:root {
  --main-primary: #2464ad;
  --main-secondary: #e2f0ff;
}

body {
  background-color: var(--main-primary);
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 100;
  position: relative;
}

.links {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile {
  float: right;
  align-self: center;
  display: flex;
}

.MuiAvatar-root {
  margin: 9px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px 24px 24px 24px;
  width: calc(100% - 472px);
  gap: 24px;
  justify-content: space-evenly;
  max-height: 350vh;
}

.imgBox {
  grid-column: main;
  grid-row: image;
}

.imageContainer {
  position: relative;
  padding-bottom: calc((100% - 170px) * (9 / 16));
  display: flex;
  justify-content: flex-end;
  /* margin: 10px; */
}

.sidebar {
  min-width: 250px;
  max-width: 400px;
  width: calc(100%-20px);
  grid-column: sidebar;
  grid-row: image / span 5;
  color: white;
  background-color: #282c34;
  position: relative;
}

.originalSidebar {
  min-width: 250px;
  max-width: 400px;
  width: calc(100%-20px);
  grid-column: original;
  color: white;
  background-color: #282c34;
  position: relative;
  justify-self: end;
}

.newSidebar {
  min-width: 250px;
  max-width: 400px;
  width: calc(100%-20px);
  grid-column: new;
  color: white;
  background-color: #282c34;
  position: relative;
  justify-self: start;
}

.updateButton {
  margin: 24px 8px !important;
}

.break {
  flex-basis: 100%;
  height: 100%;
  width: 100%;
  order: 4;
}

.gridMain {
  display: grid;
  grid-template-columns:
    [marginLeft] 1fr [main] calc(60vw) [padding] 24px [sidebar] minmax(
      25%,
      380px
    )
    [marginRight] 1fr;
  grid-template-rows: [image] auto [tabs] auto;
  grid-auto-columns: auto;
  width: 100%;
  margin-top: 24px;
}

.compareGrid {
  display: grid;
  grid-template-columns: [marginLeft] 1fr [original] 5fr [center] 200px [new] 5fr [marginRight] 1fr;
  grid-template-rows: [sidebar] auto [tabs] auto;
  grid-auto-columns: auto;
  justify-items: center;
  width: 100%;
  margin-top: 24px;
}

.imagesMain {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 150px;
  justify-self: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
}

.imageSmall {
  width: 90%;
  padding: 5%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.blasterActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
}

.detailGrid {
  display: grid;
  grid-template-columns: [image] 136px [name] 2fr [creator] 1fr [propulsion] 80px [fps] 120px [links] 120px [actions] 300px;
  width: calc(100% - 24px);
  margin: 12px;
  height: 76px;
}

.detailHeaderGrid {
  display: grid;
  grid-template-columns: [image] 128px [name] 1.5fr [creator] 1fr [propulsion] 100px [fps] 140px;
  /* grid-template-rows: [yes] 72px; */
  margin: 12px;
  height: 72px;
  grid-auto-flow: column;
  /* justify-items: center; */
  /* align-items: center; */
}

#headerSearchCard {
  width: 900px;
  height: 422px;
  margin-left: 10px;
}

.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
  bottom: -27px;
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
  bottom: -27px;
}

.react-multi-carousel-list {
  overflow: unset !important;
}

.react-multi-carousel-dot-list {
  bottom: -17px !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #2464ad;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loaderBox {
  /* margin: 5% 24px 5% 24px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cardHolder {
  max-width: 90%;
  margin: 40px auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
}

.profileCardHolder {
  /* max-width: 90%; */
  margin: 40px auto;
  margin-left: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 600px;
}

.searchHitsHolder {
  display: flex;
  justify-content: center;
  margin: 24px;
  flex-direction: column;
}

.profileCard {
  width: 90%;
  margin: 24px 0px 0px 5%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.profileFields {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.searchHome .ais-Hits-list {
  max-width: 90%;
  margin: 40px auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
}

.searchHome .ais-Hits-list .ais-Hits-item {
  border-radius: 0px;
  background: 0;
  box-shadow: none;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.blasterCardWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  justify-self: center;
  max-width: 325px;
}

.menu {
  margin: 0px 20px 0px 10px;
  display: flex;
  justify-content: center;
}

.menuIcon {
  padding: 6px !important;
  min-width: 0px !important;
}

.menuItem {
  padding: 2px !important;
}

.menuButton {
  justify-content: flex-start !important;
  font-weight: 700 !important;
  gap: 10px;
  width: 100%;
}

.footerWrap {
  position: "sticky";
  bottom: 0;
  width: "100%";
}

.coolScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.coolScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.coolScroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px var(--main-primary);
  box-shadow: inset 0 0 6px var(--main-primary);
}

.heroImg {
  width: calc(100% - ((9 / 16) * 10%));
  height: 90%;
  justify-self: center;
  align-self: center;
  border-radius: 25px;
  object-fit: cover;
}

.imgCard {
  display: flex;
  width: 100%;
  flex-basis: auto;
  justify-self: center;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;
}

.description {
  padding: 12px;
  min-height: 50px;
}

.tabBox {
  display: flex;
  flex-direction: column;
  grid-column: main;
  grid-row: tabs;
  width: 100%;
  order: 3;
  margin-top: 20px;
}

.addDesc {
  min-height: 300px;
  margin: 12px;
  position: relative;
}

.addImageCard {
  margin-top: 20px;
  margin-bottom: 20px;
  order: 2;
}

.addImageButton {
  width: 10%;
  height: 54px;
}

.searchCTA {
  width: calc(50%-48px);
  aspect-ratio: 2/1;
  margin: 100px 25% 100px 25%;
  display: flex;
  justify-content: center;
  padding: 24px;
  align-items: center;
  background-image: url(img/blasters.jpg);
  background-size: 110% 145%;
  background-position: center;
}

.searchCTAText {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 3%;
}

.searchCTAButton {
  font-size: 1.2em !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 900px) {
  .gridMain {
    grid-template-rows: [image] auto [sidebar] auto [tabs] auto;
    grid-template-columns: [main] 100%;
    margin-top: 0px;
  }

  .sidebar {
    grid-column: main;
    grid-row: sidebar;
    max-width: 900px;
    margin: 12px;
  }

  .imgBox {
    justify-content: center;
  }

  .imgCard {
    grid-column-start: 1;
    margin: 12px;
    width: auto;
  }

  .imagesMain {
    grid-column-start: 1;
    margin: 0px 12px 0px 12px;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: 120px;
    width: auto;
  }

  .imageContainer {
    position: relative;
    width: 100%;
    padding-bottom: calc((100% * (9 / 16)) + 132px);
  }

  .flexList {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-y: hidden;
  }

  .imageSmall {
    width: 150px;
    padding: 5%;
  }

  .description {
    margin: 12px;
    width: auto;
  }

  .tabBox {
    margin: 24px 12px 12px 12px;
    width: calc(100% - 24px);
    order: 5;
  }

  .addImageCard {
    margin: 12px;
    width: auto;
  }

  .addImageButton {
    width: auto;
  }
}

button {
  align-self: center;
}

.title {
  align-self: center;
  margin: 10px;
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.searchHeaderCard {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sideHead {
  height: 60px;
  display: flex;
  align-items: center;
}

.featureContainer {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
}

.footerContainer {
  height: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navLink {
  padding: 20px;
  color: white;
}

.addMainGrid {
  display: grid;
  margin-top: 50px;
}

.addSideBar {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 90%;
  margin: 30px 0px 0px 5%;
}

.checkGrid {
  display: grid;
  grid-column: auto auto;
}

.addImage {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.addImageContainer {
  position: relative;
  width: 100%;
  padding-bottom: calc((100% - 170px) * (9 / 16));
}

@media only screen and (max-width: 900px) {
  .addImage {
    flex-direction: column-reverse;
    gap: 0px;
  }

  .addImageContainer {
    position: relative;
    width: 100%;
    padding-bottom: calc((100% * (9 / 16)) + 162px);
  }
}

.ais-Hits-item {
  list-style-type: none;
  padding: 0px;
}

.ais-Hits-list {
  padding-inline-start: 0px;
}

.searchBox {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 12px;
  max-width: 300px;
  flex-grow: 1;
  padding: 20px;
  transition-property: left !important;
  transition-duration: 1s !important;
}

.searchCheckbox {
  padding: 2px !important;
}

.ais-SearchBox {
  flex-grow: 2;
}

.hitsContainer {
  flex-grow: 2;
}

.sortContainer {
  display: flex;
  flex-direction: column;
}

.blasterCardMedia {
  transition: 0.2s all ease-in-out;
}

/* .blasterCardMedia:hover {
  transform: scale(1.1);
} */

.blasterCardWrapper:hover .blasterCardMedia {
  transform: scale(1.15);
}

.carouselCardTitle {
  font-size: 32px !important;
}

.homeImages {
  width: 90%;
  margin: 24px 0px 60px 5%;
  height: 600px;
}

.homeImages {
  object-fit: cover;
  height: 600px;
}

.homeImageContainer {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.searchBlasterLinks{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
}

.searchHolder {
  margin: 24px 36px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.searchMobileHeader {
  display: none;
}

.searchMobileText {
  margin: 8px !important;
  width: calc(100% - 80px);
}

.searchHitsFull {
  width: 100%;
}

.searchHitImageLink {
  grid-column: image;
  grid-row: desc;
}

.searchHitImage {
  width: 136px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.searchActions {
  grid-column: actions;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.searchCreator {
  margin-left: 12px;
  grid-column: creator;
  padding-top: 5px;
}

.searchPropulsion {
  margin-left: 12px;
  grid-column: propulsion;
  padding-top: 5px;
  text-align: center;
}

.searchFPS {
  margin-left: 12px;
  grid-column: fps;
  padding-top: 5px;
  text-align: center;
}

.searchCloseButton {
  display: none !important;
}

.headerSearchPopper {
  z-index: 100;
}

.headerSearchNameText {
  margin-left: 12px;
  margin-bottom: 0px;
  grid-column: name;
  margin-top: 6px;
  height: 30px;
}

.headerSearchDescText {
  margin-left: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
  grid-column: name;
  margin-top: 6px;
  height: 30px;
  font-size: "16px";
}

.headerSearchCreator {
  grid-column: creator;
  text-align: center;
  margin: 0px;
  align-self: center;
}

.headerSearchPropulsion {
  grid-column: propulsion;
  text-align: center;
  margin: 0px;
  align-self: center;
}

.headerSearchFPS {
  grid-column: fps;
  text-align: center;
  margin: 0px;
  align-self: center;
}

@media only screen and (max-width: 1400px) {
  .searchHolder {
    margin: 24px 24px;
    display: flex;
    flex-direction: column;
  }

  .searchCloseButton {
    display: inline-flex !important;
    width: 100px !important;
  }

  .searchMobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .searchBox {
    position: absolute;
    top: 160px;
    left: -350px;
    z-index: 100;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.459) !important;
    border-style: solid;
    border-color: rgb(192, 192, 192);
    height: 75vh;
    width: 250px;
  }

  .searchBox.transition {
    left: 16px;
    overflow: scroll;
  }

  .searchHitsFull {
    min-height: 82vh;
  }

  .searchSidebarText {
    display: none !important;
  }

  .searchHitsHolder {
    margin: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .headerSearchPopper {
    z-index: 100;
    inset: unset !important;
    transform: unset !important;
    width: 96%;
    margin-left: 2% !important;
    top: 68px !important;
  }

  #headerSearchCard {
    width: 100%;
    height: 422px;
    margin-left: unset;
    transform-origin: top !important;
  }

  .detailHeaderGrid {
    margin: 12px 6px;
    grid-template-columns: [image] 128px [name] 2fr [other] 3fr;
    grid-template-rows: [creator] 28px [propulsion] 22px [fps] 22px;
  }

  .headerSearchName {
    grid-column-start: name;
    grid-column-end: span 5;
    grid-row-start: 1;
    grid-row-end: span 3;
    margin: 0px 10px !important;
  }

  .headerSearchNameText {
    font-size: 1.2em;
    text-align: start;
    margin: 0px;
  }

  .headerSearchDescText {
    font-size: 0.6em;
    text-align: start;
    margin-left: 0px;
  }

  .headerSearchCreator {
    grid-row: creator;
    grid-column-start: other;
    grid-column-end: span 2;
    margin: 4px;
    padding: 0px;
    text-align: end;
    font-size: 0.9em;
    margin: 4px 8px !important;
  }

  .headerSearchPropulsion {
    grid-row: propulsion;
    grid-column-start: other;
    grid-column-end: span 1;
    margin: 4px;
    padding: 0px;
    text-align: end;
    font-size: 0.7em;
    font-weight: 500;
    margin: 4px 8px !important;
  }

  .headerSearchFPS {
    grid-row: fps;
    grid-column-start: other;
    grid-column-end: span 1;
    margin: 4px;
    padding: 0px;
    text-align: end;
    font-size: 0.7em;
    font-weight: 500;
    margin: 4px 8px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .searchCTA {
    width: unset;
    margin: 5%;
    aspect-ratio: unset;
  }

  .searchCTAText {
    padding: 5%;
  }

  .homeImages {
    width: 80%;
    margin: 24px 0px 60px 10%;
    height: 50vw;
    /* aspect-ratio: 2/1; */
  }

  .homeImage {
    height: 50vw;
  }

  .detailGrid {
    display: grid;
    grid-template-columns: [image] 136px [name] 2fr;
    grid-template-rows: [image] 76px [creator] 25px [propulsion] 25px [fps] 25px [actions] 60px;
    width: 100%;
    margin: 12px 0px;
    height: 206px;
  }

  .searchActions {
    grid-row: actions;
    grid-column-start: 1;
    grid-column-end: span 5;
  }

  .searchBlasterLinks {
    grid-row-start: fps;
    grid-column: span 3;
    grid-column-start: name;
    grid-column-end: span 5;
    justify-self: end;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .searchName {
    grid-column-start: name;
    grid-column-end: span 5;
    margin: 0px 10px !important;
  }

  .searchNameText {
    font-size: 1.3em;
    text-align: end;
  }

  .searchDescText {
    font-size: 0.9em;
    text-align: end;
  }

  .searchCreator {
    grid-row: creator;
    grid-column-start: image;
    grid-column-end: span 2;
    margin: 4px;
    padding: 0px;
    text-align: start;
    font-size: 1.3em;
    margin: 4px 8px !important;
  }

  .searchPropulsion {
    grid-row: propulsion;
    grid-column-start: image;
    grid-column-end: span 1;
    margin: 4px;
    padding: 0px;
    text-align: start;
    font-size: 1.17em;
    font-weight: 500;
    margin: 4px 8px !important;
  }

  .searchFPS {
    grid-row: fps;
    grid-column-start: image;
    grid-column-end: span 1;
    margin: 4px;
    padding: 0px;
    text-align: start;
    font-size: 1.17em;
    font-weight: 500;
    margin: 4px 8px !important;
  }
}

@media only screen and (max-width: 900px) {
  .carouselCardTitle {
    font-size: 5vw !important;
  }

  .searchHolder {
    margin: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .react-multi-carousel-dot button {
    width: 8px !important;
    height: 8px !important;
    margin-right: 3px !important;
    margin-bottom: 8px !important;
  }

  .searchHitsHolder {
    margin: 8px;
  }

  .searchHolder {
    margin: 8px;
  }

.headerSearchNameText{
  font-size: 1.3em;
}

  .headerSearchDescText {
    font-size: 0.7em;
    text-align: start;
    margin-left: 0px;
  }

  .headerSearchCreator {
    display: none;
  }

  .headerSearchPropulsion {
    display: none;
  }

  .headerSearchFPS {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .react-multi-carousel-dot button {
    width: 7px !important;
    height: 7px !important;
    margin-right: 3px !important;
    margin-bottom: 8px !important;
  }
}

@media only screen and (max-width: 300px) {
  .title {
    font-size: 0px !important;
  }

  .react-multi-carousel-dot button {
    width: 6px !important;
    height: 6px !important;
    margin-right: 2px !important;
  }
}
